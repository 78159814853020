import React from "react";

import { Header } from "../components/parts/Header";
import { Questions } from "../components/Questions";
import { Footer } from "../components/Footer";

import { Layout } from "../components/parts/Layout";

const QuestionPage = () => {
  return (
    <Layout>
      <Header
        title={"よくあるご質問"}
        description={"よくあるご質問"}
      />
      <Questions />
      <Footer />
    </Layout>
  )
}

export default QuestionPage
