import React from "react";
import styled from "styled-components";

import { Container } from "../components/parts/Container";
import { TitleView } from "../components/parts/TitleView";
import { SubTitleView } from "../components/parts/SubTitleView";
import { Button } from "../components/parts/Button";
import { WhiteWrapper } from "../components/parts/WhiteWrapper";

import { Color, FontSize } from "../utils/Const";
import { QuestionsData } from "../utils/QuestionsData";

const QuestionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 1.0rem 0;
`

const QuestionsArea = styled.dl`
  width: 100%;
  text-align: left;
  margin: 1.0rem 0;
`;

const Question = styled.dt`
  position: relative;

  display: flex;
  align-items: center;
  min-height: 50px;
  margin-left: 65px;
  font-size: ${FontSize.xs};
  border-bottom: 2px solid ${Color.Border};
  box-sizing: border-box;
  &::before {
    content: "Q";

    position: absolute;
    left: -65px;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 50px;
    height: 50px;
    margin-right: 15px;

    text-align: center;
    border-radius: 50%;
    color: ${Color.Orange};
    background-color: ${Color.White};
    border: 2px solid ${Color.Orange};
    font-size: ${FontSize.sm};
    font-weight: bold;
  }
`

const Answer = styled.dd`
  position: relative;

  display: flex;
  align-items: center;
  min-height: 50px;
  margin-left: 65px;
  margin-top: 1.5rem;
  margin-bottom: 3rem;

  font-size: ${FontSize.xs};
  border-bottom: 2px solid ${Color.Border};
  box-sizing: border-box;
  a {
    display: contents;
  }
  &::before {
    content: "A";

    position: absolute;
    left: -65px;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 50px;
    height: 50px;
    margin-right: 15px;

    text-align: center;
    border-radius: 50%;
    color: ${Color.White};
    background-color: ${Color.Orange};
    font-size: ${FontSize.sm};
    font-weight: bold;
  }
`

const ButtonWrapper = styled.div`
  width: 100%;
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const ButtonDescription = styled.p`
  width: 100%;
  text-align: center;
  margin: 1rem 0;
`

export const Questions = () => {
  return(
    <WhiteWrapper>
      <Container>
        <TitleView>よくあるご質問</TitleView>
        <SubTitleView>費用について</SubTitleView>
        <QuestionsWrapper>
          <QuestionsArea>
            {QuestionsData.filter((n) => n.category === `費用`).map((n) => {
              return (
                <>
                  <Question>{n.question}</Question>
                  <Answer dangerouslySetInnerHTML={{__html: n.answer}} />
                </>
              )
            })}
          </QuestionsArea>
        </QuestionsWrapper>
        <SubTitleView>機能について</SubTitleView>
        <QuestionsWrapper>
          <QuestionsArea>
            {QuestionsData.filter((n) => n.category === `機能`).map((n) => {
              return (
                <>
                  <Question>{n.question}</Question>
                  <Answer dangerouslySetInnerHTML={{__html: n.answer}} />
                </>
              )
            })}
          </QuestionsArea>
        </QuestionsWrapper>
        <SubTitleView>その他について</SubTitleView>
        <QuestionsWrapper>
          <QuestionsArea>
            {QuestionsData.filter((n) => n.category === `その他`).map((n) => {
              return (
                <>
                  <Question>{n.question}</Question>
                  <Answer dangerouslySetInnerHTML={{__html: n.answer}} />
                </>
              )
            })}
          </QuestionsArea>
        </QuestionsWrapper>
        <ButtonWrapper>
          <ButtonDescription>当ページにご質問が見当たらない場合や、その他のご質問がある場合は、以下のフォームよりお問い合わせください。</ButtonDescription>
          <Button href={`https://otsutome.net/contact`}>お問い合わせ</Button>
        </ButtonWrapper>
      </Container>
    </WhiteWrapper>
  );
}